@font-face {
  font-family: "Poppins";
  src:
    url(./Poppins-Regular.ttf)
    format("truetype");
}

@font-face {
  font-family: "Josefin Sans";
  src:
    url(./JosefinSans-Regular.ttf)
    format("truetype");
}

.hero {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

@media (min-aspect-ratio: 3/2) and (max-width: 490px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@490x331.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (min-aspect-ratio: 3/2) and (min-width: 491px) and (max-width: 1224px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@1224x816.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (min-aspect-ratio: 3/2) and (min-width: 1225px) and (max-width: 1470px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@1470x981.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (min-aspect-ratio: 3/2) and (min-width: 1471px) and (max-width: 2448px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@2448x1632.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (min-aspect-ratio: 3/2) and (min-width: 2449px) and (max-width: 3672px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@3672x2448.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (min-aspect-ratio: 3/2) and (min-width: 3673px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@4896x3264.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (max-aspect-ratio: 3/2) and (max-height: 331px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@490x331.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (max-aspect-ratio: 3/2) and (min-height: 332px) and (max-height: 816px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@1224x816.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (max-aspect-ratio: 3/2) and (min-height: 817px) and (max-height: 981px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@1470x981.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (max-aspect-ratio: 3/2) and (min-height: 982px) and (max-height: 1632px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@2448x1632.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (max-aspect-ratio: 3/2) and (min-height: 1633px) and (max-height: 2448px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@3672x2448.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}
@media (max-aspect-ratio: 3/2) and (min-height: 3264px) {
  .photo {
      background:
          url(./henry-co-3coKbdfnAFg-unsplash@4896x3264.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
  }
}

.message {
  z-index: 1;
  width: 100%;
  padding: 16px;
  text-align: center;
  color: #efefef;
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

.message .title {
  font-family: "Poppins", sans-serif;
  font-size: 2em;
}

.message .subtitle {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1em;
}

.attribution {
  z-index: 2;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 8px;
  font-size: 1em;
}

.attribution, .attribution a {
  color: #a0a0a0;
}
